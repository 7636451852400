import reportWebVitals from "src/reportWebVitals";
import Swal from "sweetalert2";
import useFetch from "../UseFetch";




class Auth {
  constructor() {
    this.authenticated = false;
  }

  async login(cb) {
    let username;
    let passwd;

    let success = false;

    //Getting username and password with sweetalert popup
    const { value: formValues } = await Swal.fire({
      title: 'Log in',
      html:
        '<input id="swal-input1" class="swal2-input" placeholder="username">' +
        '<input type="password" id="swal-input2" class="swal2-input" placeholder="password">',
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById('swal-input1').value,
          document.getElementById('swal-input2').value
        ]
      }
    })

    if (formValues) {
      username = formValues[0];
      passwd = formValues[1];
    }
  //HC819angl
    let userinfo = await this.checkAuth(username, passwd);
    console.log(userinfo);
    if(userinfo.status == "authorized") {
      sessionStorage.setItem("username",userinfo.username);
      sessionStorage.setItem("userid",userinfo.userid);
      sessionStorage.setItem("token",userinfo.token);

      success = true;
    }

    cb(success);    
  }

  async checkAuth(username, passwd) {
    //Sending over data for server authentication
    try {
      let response = await fetch("LearnHungarian/server.php/authuser", {
        method: "POST",
        body: JSON.stringify({
          "username": username,
          "password": passwd
        })
      });
      let data = await response.json();
      return data;
    }
    catch(e) {
      return {status: "unauthorized"};
    }

  }

  async validateUser(username, userid, token) {
    try {
      let response = await fetch("LearnHungarian/server.php/validateuser", {
        method: "POST",
        body: JSON.stringify({
          "username": username,
          "userid": userid,
          "token": token 
        })
      });
      let data = await response.json();
      return data;

    }
    catch(e) {
      return {status: "unvalidated"};
    }
  }


  async logout(cb) {
    
    let username = sessionStorage.getItem("username") || null;
    let success = false;

    if(username !== null) {
      try {
        let response = await fetch("LearnHungarian/server.php/logout", {
          method: "POST",
          body: JSON.stringify({
            "username": username
          })
        })
  
        let data = await response.json();
  
        success = data.status === "success";

        if(success) {
          sessionStorage.clear();
          sessionStorage.setItem("auth","false");
        }
      }
      catch(e) {
        success = false;
      }
    }
    


    cb(success);

  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();