import PropTypes from "prop-types";
import {HashLink  as Link } from "react-router-hash-link";

const FilledButton = ({text, classMod, linkTo}) => {
  return (
    <Link to={ linkTo } className={`filledButton ${classMod}`}>
        { text }
    </Link>
  )
}

FilledButton.propTypes = {
  text: PropTypes.string,
  classMod: PropTypes.string
}

FilledButton.defaultProps = {
  text: "Button",
  classMod: "",
  linkTo: "#"
}

export default FilledButton
