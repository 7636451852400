import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// import CalendarDate from "./CalendarDate";


const Calendar_dates = ({dates, displayedDate, selectedDate, onSelect}) => {
  //returns the id of the day, if it is amongst the selected
  //returns -1 if no day is selected in the month
  const [dayToHighlight, setDayToHighlight] = useState();

  useEffect(() => {
    const getSelectedDateId = () => {
      // debugger;
      if(selectedDate.year === displayedDate.getFullYear()) 
        {
          let helper = dates.find(day => day.month === selectedDate.month && day.dateNumber === selectedDate.dateNumber);
          if(helper != undefined)
            return helper.id;
        } 
            
      else return -1;
    }
  
    setDayToHighlight(getSelectedDateId);
  }, [selectedDate, dates, displayedDate])
  

  return (
    <div className="calendar__dates-wrapper">

      <div className="calendar__separator" ></div>
      <div className="calendar__dates-table">
        {
          dates.map((date) => (
            <CalendarDate 
              id={date.id}
              text={`${date.dateNumber}`} 
              onSelect={onSelect} 
              dayName={date.id < 7 ? true : false}
              inactiveDate={date.isInactive}
              isSelected={date.id === dayToHighlight ? true : false}
              isToday={date.isToday}
              reservedState={date.reservedState}
            />
        ))}
      </div>

      <div className="calendar__separator" ></div>
    </div>
    );
};

const CalendarDate = ({ id, text, onSelect, dayName, inactiveDate, isSelected, isToday, reservedState, }) => {

  const createClassString = () => {
    let clsName = "calendar__date-text-wrapper "  + 
    (dayName ? "calendar__date-text-wrapper--day-name " : "") +
    (inactiveDate ? "calendar__date-text-wrapper--inactive " : "") +
    (isSelected ? "calendar__date-text-wrapper--selected " : "") +
    (isToday ? "calendar__date-text-wrapper--today " : "") + 
    ("calendar__date-text-wrapper--"+reservedState);
    // (selectedDate.id === id && sele ? "calendar__date-text-wrapper--selected " : "") +


    return clsName;
  };

  return (
      <div className={createClassString()} onClick={() => onSelect(id)}>

        <h4 
          className={"calendar__date-text "}
          >
            {text}
        </h4>
      </div>
    );
};

/* PropTypes */

Calendar_dates.propTypes = {
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  onSelect: PropTypes.func,
  displayedDate: PropTypes.object,
  selectedDate: PropTypes.object,
};

CalendarDate.propTypes = {
  id: PropTypes.number,
  text: PropTypes.string,
  onSelect: PropTypes.func,
  dayName: PropTypes.bool,
  inactiveDate: PropTypes.bool,
  isSelected: PropTypes.bool,
  isToday: PropTypes.bool,
  reservedState: PropTypes.string,
};

/* DefaultProps */

Calendar_dates.defaultProps = {
  day: "01",
  month: "January",
  year: "2021"
};

CalendarDate.defaultProps = {
  text: "1"
};

export default Calendar_dates;