import PropTypes from "prop-types";
import { BiChevronLeft } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";
import { BiCalendarEvent } from "react-icons/bi"

const Calendar_header = ({
  day,
  month,
  year,
  onPrevMonth,
  onNextMonth,
  onCurrentDate,
}) => {
  return ( 
    <div className="calendar__header">
      <div className="calendar__header-date">
        {/* <h2 className="calendar__header-date__day">
          {day}
        </h2> */}
        <div className="calendar__header-date__ymWrapper">
          <h3 className="calendar__header-date__month"> {month} </h3>
          <h4 className="calendar__header-date__year"> {year} </h4>
        </div>
      </div>

      <div className="calendar__button-box">
        <div onClick={ onCurrentDate } className="calendar__button">
          <BiCalendarEvent className="calendar__button__to-current-date" />
        </div>
        <div onClick={ onPrevMonth } className="calendar__button calendar__button--left">
          <BiChevronLeft className="calendar__button__arrow" />
        </div>
        <div onClick={ onNextMonth }  className="calendar__button calendar__button--right">
          <BiChevronRight className="calendar__button__arrow" />
        </div>
      </div>

    </div>
    );
};

Calendar_header.defaultProps = {
  day: "01",
  month: "January",
  year: "2021"
};
Calendar_header.propTypes = {
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  onPrevMonth: PropTypes.func,
  onNextMonth: PropTypes.func,
  onCurrentDate: PropTypes.func,
};
export default Calendar_header;