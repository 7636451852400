import { Link } from "react-router-dom";

import { useState, useEffect } from "react";

import NavToggler from "../micro/NavToggler";

const Nav = () => {
  const [isClosed, setIsClosed] = useState(true)

  useEffect(() => {
    document.querySelector(".nav").addEventListener("click", e => {
      if(e.target.tagName === "LI")
        setIsClosed(true);
        window.scrollTo(0,0);
    });
  }, [])

  return (
    <>
      <nav className={`nav ${ !isClosed && "nav--shown"}`}>
        <ul className="nav__list">
          <Link to="/" className="nav__link">
            <li className="nav__item">home</li>
          </Link>
          <div className="nav__separator"></div>
          <Link to="./about" className="nav__link">
            <li className="nav__item">about</li>
          </Link>
          <div className="nav__separator"></div>
          <Link to="./classes" className="nav__link">
            <li className="nav__item">classes</li>
          </Link>
          <div className="nav__separator"></div>
          <Link to="./contact" className="nav__link">
            <li className="nav__item">contact</li>
          </Link>
        </ul>
      </nav>

      <NavToggler navClosed={isClosed} onToggle={() => setIsClosed(!isClosed)} />
    </>
  )
}

export default Nav
