
import { BiSmile } from "react-icons/bi";


const AboutIntro = () => {
  return (
    <section className="intro">
      <div className="intro__box">
            <div className="intro__picture"></div>
    
            <h3 className="intro__title">A few words about me</h3>

            <div className="intro__dots">
                <div className="intro__dot"></div>
                <div className="intro__dot"></div>
                <div className="intro__dot"></div>
            </div>

            <p className="intro__text">
                Szia!
                This is Eszter. I have a passionate love for my mother tongue and I've been teaching it for years. In fact, teaching for me is not only about the language, but rather about connecting with people.
            </p>
            <p className="intro__text">
              My students have different goals in learning Hungarian and have different learning mentality. Therefore, I believe that it is essential to understand where people are coming from: a mother of two, a student preparing for Hungarian language exams and a person with dyslexia: everyione needs a specific approach and I adapt my lessons to their needs. 
            </p>
            <p className="intro__text">
              Maybe your goal is just to talk to the vendors on a market, to know where to buy the best seasonal fruits and lángos, to invite your neighbour for a glass of Egri Bikavér or to discuss the latest sport news with your Hungarian colleagues, it's not so difficult to learn it. I admit Hungarian is not the easiest language but it has its own logic and getting it makes learning Hungarian much faster. 
            </p> 
            <p className="intro__text">
              Let me help you to achieve your goals and let me be your Hungarian teacher. You won't regret it <BiSmile />
              </p>
        </div>
    </section>
  )
}

export default AboutIntro
