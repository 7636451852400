import { BiMenu, BiX } from "react-icons/bi";



const NavToggler = ({navClosed, onToggle}) => {
  
  return (
    <div tabIndex="0" onClick={onToggle} className={`nav-toggler ${ navClosed ? "" : "nav-toggler--close-state"}`} >
        <BiX className="nav-toggler__icon" style={{opacity: navClosed ? 0 : 1}} />
        <BiMenu className="nav-toggler__icon" style={{opacity: navClosed ? 1 : 0}} />
    </div>
  )
}

export default NavToggler
