import { BiBookOpen, BiJoystickAlt, BiTestTube } from "react-icons/bi"

const ClassesMaterials = () => {
  return (
    <div className="classesMaterials">

      <h3 className="classesMaterials__title">Teaching materials I use</h3>

      <div className="classesMaterials__cardContainer">

        <div className="classesMaterials__card classesMaterials__card--grid">
          <BiBookOpen className="classesMaterials__card__icon" />
          <p className="classesMaterials__card__content">MagyarOK A1</p>
          <p className="classesMaterials__card__content">MagyarOK A2</p>
          <p className="classesMaterials__card__content">MagyarOK B1</p>
          <p className="classesMaterials__card__content">MagyarOK B2</p>
        </div>

        <div className="classesMaterials__card classesMaterials__card--games">
          <BiJoystickAlt className="classesMaterials__card__icon" />
          <p className="classesMaterials__card__content">Online and interactive language games</p>
        </div>

        <div className="classesMaterials__card classesMaterials__card--tailored">
          <BiTestTube className="classesMaterials__card__icon" />
          <p className="classesMaterials__card__content">Other materials <br />tailored to the needs of the students</p>
        </div>

      </div>
    </div>
  )
}

export default ClassesMaterials
