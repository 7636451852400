import { Hero } from "../macro/Hero";
import FilledButton from "../micro/FilledButton";
import { BiHappy} from "react-icons/bi";


//images
import EszterHomepage from "../../img/Eszter_homepage.png"

import { BgOverlay, BgBlurred } from "../groups/BgEffects";


const HeroHome = ({offsetY, linkTo}) => {
  return (
    <Hero classMod="hero--home">
      <div className="home__bg" 
        style={{ backgroundPosition: `0  ${-0.5*offsetY}px`}}>
      </div>
      <BgOverlay bgColor={"#FFFFFF"} bgOpacity="0.5" />
        <div className="hero--home__cell hero--home__cell--image-container">
          <img className="hero--home__image" src={EszterHomepage}alt="Eszter" title="Eszter" />
        </div>

        <div className="hero--home__cell hero--home__cell--introduction">
          <h1 className="hero--home__title">
            learn <strong className="hero--home__title--strong">Hungarian</strong> with me 
          </h1>

          <div className="hero--home__title-separator"></div>

          <p className="hero--home__introduction-text">
          Maybe your goal is just to talk to the vendors on a market, to know where to buy the best seasonal
fruits and lángos, to invite your neighbour for a glass of Egri Bikavér or to discuss the latest sport
news with your Hungarian colleagues in Hungarian, I can help you with that.
Let me help you to achieve your goals and let me be your Hungarian teacher. You won't regret it <BiHappy />
          </p>

          <FilledButton linkTo={linkTo} text="read more" classMod="filledButton--home"/>

        </div>
      </Hero>
  )
}

const HeroAbout = ({offsetY}) => {
  return (
    <Hero classMod="hero--about">
      <div className="about__bg" 
        style={{ backgroundPosition: `0  ${-0.5*offsetY}px`}}>
      </div>
      <BgOverlay bgColor={"#FFFFFF"} bgOpacity="0.6" />
      <div className="hero--about__cell">
        <h1 className="hero--about__title">Meet Eszter</h1>
        <h2 className="hero--about__subtitle">Learn a bit about your teacher</h2>
      </div>
    </Hero>
  )
}

const HeroClasses = ({offsetY}) => {
  return (
    <Hero classMod="hero--classes">
      <div className="classes__bg" 
        style={{ backgroundPosition: `0  ${-0.5*offsetY}px`}}>
      </div>
      <BgOverlay bgColor={"#FFFFFF"} bgOpacity="0.8" />
        <div className="hero--classes__cell">
          <h1 className="hero--classes__title">Classes</h1>
          <h2 className="hero--classes__subtitle">I offer only online classes. Due to the coronavirus pandemic I started to teach online and it made me
realise how effective online classes are and how many online creative teaching materials can be
used. Not to mention the time you save by staying at home.</h2>
        </div>
    </Hero>
  )
}

const HeroContact = () => {
  return (
    <Hero classMod="hero--contact">
      <BgOverlay bgColor={ "#AAA" } bgOpacity="1" /> 
      <BgBlurred />

      
    </Hero>
  )
}

export  { HeroHome, HeroAbout, HeroClasses, HeroContact }