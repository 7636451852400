import React, { useEffect, useState } from "react";

import { useLayoutEffect  } from "react";
import { BiInfoSquare, BiLogIn } from "react-icons/bi";

import {Route, Redirect, useLocation} from "react-router-dom"
import auth from "./auth.js";

import Swal from "sweetalert2";

import Admin_mainPage from "../admin/Admin_mainPage.jsx";
import Admin_toolbar from "../admin/Admin_toolbar.jsx";
import Admin_login from "../admin/Admin_login.jsx";



const AdminRoute = ({...rest}) => {
  const [isValidated, setIsValidated] = useState(false);

  //On reload load data from sessionstorage into the usestate hook
  const loadSessionData = () => {
    if(sessionStorage.getItem("auth") == null) 
      sessionStorage.setItem("auth","false");
    
    setIsValidated(JSON.parse(sessionStorage.getItem("auth")));
    console.log(sessionStorage.getItem("auth"));
  }

  const checkValidation = async () => {
    if(sessionStorage.getItem("userid") == null) {
      setIsValidated(false);
      return;
    }

    let username = sessionStorage.getItem("username");
    let userid = sessionStorage.getItem("userid");
    let token = sessionStorage.getItem("token");


    let status = await auth.validateUser(username, userid, token);

    console.log(status);

    if(status.status == "validated")
      setIsValidated(true);
    else 
      setIsValidated(false);
  }

  useEffect(() => {
    loadSessionData();
    checkValidation();
  }, []);
  

  return (  
    <>
      {
        isValidated ?
            <Admin_mainPage {...rest}
                  onLogout = {() => {
                    auth.logout(success => {
                      sessionStorage.setItem("auth","false");
                      setIsValidated(!success);
                    })
                  }} />
        : 
            <Admin_login {...rest} 
                  onLogin = {() => {
                    auth.login(success => {
                      if(success) {
                        Swal.fire({
                          icon: 'success',
                          title: 'Login successful',
                          showConfirmButton: false,
                          timer: 1500
                        })
                        sessionStorage.setItem("auth","true");
                      }
                      else {
                        Swal.fire({
                          icon: 'error',
                          title: 'Login failed',
                          text: 'something went wrong',
                        })
                      }
                      setIsValidated(success);
                    })
                    
                  }} />
      }           

    </>
  )
}

export {AdminRoute};