import {HashRouter as Router, Route } from "react-router-dom";

import { AdminRoute } from "./components/macro/Admin_route";

import { useState, useEffect } from "react";

// Components
import { HeroHome, HeroAbout, HeroClasses, HeroContact } from "./components/groups/Heroes";
import Nav from "./components/macro/Nav";
import Calendar from "./components/calendar/Calendar";

//Effects

//Home page
import HowITeach from "./components/macro/HowITeach";
import References from "./components/macro/References";

//About page
import AboutIntro from "./components/macro/AboutIntro";

//Classes page
import ClassesMaterials from "./components/macro/ClassesMaterials";
import ClassesPrices from "./components/macro/ClassesPrices";

//ADMIN 

import Footer from "./components/macro/Footer";


function App() {

  //Code for parallax scrolling 
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [])
  // /code for parallax scrolling

  //Tracks if render is needed for footer and navbar (needed on every page except admin)


  return (
  <>
    <Router>
      <Route path="/" exact render={(props) => (
        <>
          <Nav />
          <HeroHome offsetY={offsetY} linkTo="./#howIteachAnchor" />

          <a className="site-anchor" href="#" id="howIteachAnchor"></a>
          <HowITeach />
          <References />
          <Footer />
        </>
      )} />

      <Route path="/about" render={(props) => (
        <>
          <Nav />
          <HeroAbout offsetY={offsetY}/>
          <AboutIntro />
          <Footer />
       </>
      )} />

      <Route path="/classes" render={(props) => (
        <>
          <Nav />
          <HeroClasses offsetY={offsetY}/>
          <ClassesMaterials />
          <ClassesPrices offsetY={offsetY}/>
          <Footer />
       </>
      )} />

      <Route path="/contact" render={(props) => (
        <>
          <HeroContact />
          <Nav />
          <div className="content" >
            <div className="content__cell">
              <h1 className="content__text">On-page appointment making coming soon</h1> <br />
              <h1 className="content__text">until then, you can contact me at: <strong className="content__text--strong">eszter.derii@gmail.com</strong></h1>
            </div>

            <div className="content__cell">
              {/* <Calendar /> */}
            </div>
          </div>
          <Footer />
        </>
      )} />

    
  
    </Router>
  </>
 );
}

export default App;
