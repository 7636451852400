//components
import { BgOverlay, BgBlurred } from "../groups/BgEffects";

const References = () => {
  return (
    <div className="references">
        <BgOverlay bgColor="#FFF" bgOpacity="0.7" />
        <h1 className="references__title">Testimonials</h1>
     
        
        <div className="references__ref-wrapper bg">
          <ReferenceBlock
            quote={
              "I would like to thank Déri Eszter for her work. Since my task was not just to learn Hungarian language, but to prepare for a rather specific exams from my university, I was glad to find a teacher, who was ready for non-standard learning. In 1,5 year of individual classes, I was able to pass  successfully an oral exam in Hungarian, as well as get a language certificate. I thank Eszter for her readiness to work with new tasks, to share personal experience and to сreate a friendly, positive and relaxed atmosphere, which is especially important for study of difficult languages."
            }
            person={ "-Maria Iurkova, Moscow " }
          />
          <ReferenceBlock
            quote={
              "Learning a new language has always been a little difficult for me. With dyslexia, it is always a challenge to find a good language teacher. After trying several other teachers, I can honestly say that Eszter has changed my view about learning a new language. She tailors the lessons with my dyslexia in mind, making the information easier to understand. She researched the best and newest  teaching techniques to help me learn. She is patient and flexible, always willing to re-teach the material until I understand. I am always excited to have class every week, because I know it will be fun, challenging and engaging. I would recommend her to anyone because she is such a great teacher."
            }
            person={ "-Adeola Adeusi, Nigeria " }
          />
          
        </div>
      
    </div>
  )
}

const ReferenceBlock = ({quote, person}) => {
  return (
    <div className="bg references__ref-block">
      <BgBlurred  />
      <BgOverlay  bgColor="#fff" bgOpacity="0.8" />
      <BgOverlay  bgColor={"--clr-primary-lightest"} bgOpacity={"inherit"} />

      <blockquote className="references__ref-quote">{quote}</blockquote>
      <h4 className="references__ref-person">{person}</h4>
    </div>
  )
}

export default References
