import { useState, useEffect } from "react";




const Footer = () => {
  const [curYear, setCurYear] = useState("");
  useEffect(() => {
    setCurYear((new Date()).getFullYear());
  },[]);

  return (
    <div className="footer">

        <p className="footer__sampletext">  Hungarian classes &copy; {curYear} </p>

     
        <p className="footer__support">In case you have any problems write an email to: support@hungarianclasses.hu</p>

        <p className="footer__madeby">  Made by: Gergely Déri</p>

    </div>
  )
}

export default Footer