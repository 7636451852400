import {  BiCoffee, BiHappy, BiLaptop, BiBulb, BiCollection} from "react-icons/bi";


const HowITeach = () => {
  return (
    <div className="how-i-teach">
      <h1 className="how-i-teach__title">This is how i teach</h1>

      
      <div className="how-i-teach__card-wrapper">

        <div className="how-i-teach__card">
          <BiCoffee className="how-i-teach__icon" />
          <p className="how-i-teach__text">relaxed atmosphere</p>
        </div>

        <div className="how-i-teach__card">
          <BiCollection className="how-i-teach__icon" />
          <p className="how-i-teach__text">personalized flashcards for each class</p>
        </div>

        <div className="how-i-teach__card">
          <BiHappy className="how-i-teach__icon" />
          <p className="how-i-teach__text">topics tailored to your personality</p>
        </div>

        <div className="how-i-teach__card">
          <BiBulb className="how-i-teach__icon" />
          <p className="how-i-teach__text">grammar explanation in English</p>
        </div>

        <div className="how-i-teach__card how-i-teach__last-card">
          <BiLaptop className="how-i-teach__icon"/>
          <p className="how-i-teach__text">online interactive tools and exercises </p>
        </div>

      </div>

    </div>
  )
}

export default HowITeach
