
const BgOverlay = ({additionalClasses, bgColor, bgOpacity}) => {
  return (
    <div 
      className={`bg bg--overlay ${additionalClasses}`}
      style={{ backgroundColor: bgColor, opacity: bgOpacity}}
    >

    </div>
  )
}


const BgBlurred = ({additionalClasses}) => {
  return (
    <div className={`bg bg--blurred ${additionalClasses}`}></div>
  )
}

BgOverlay.defaultProps = {
  additionalClasses: "",
  bgColor: "none",
  bgOpacity: 0,
};

BgBlurred.defaultProps = {
  additionalClasses: "",
};

export { BgOverlay, BgBlurred  }
