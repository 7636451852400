

const Hero = ({children, classMod}) => {
  return (
    <header className={`hero ${classMod}`}>
      {children}
    </header>
  )
}


export { Hero }
